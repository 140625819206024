import * as React from "react"

import Badge from "../../components/ui/badge"
import Register from "../../components/ui/register"

import heroImg from "../../images/hero-image.svg"

const Hero = () => {
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 sm:col-span-10 md:col-span-6 lg:col-span-6 flex flex-row items-center">
        <div>
          <Badge variant="primary-light" className="mb-5">LAUNCHING SOON</Badge>

          <h1 className="mb-6">The most developer friendly headless CMS</h1>

          <p className="mb-10">Boundary gives developers the ultimate toolkit to create amazing content experiences for users, without the hassle.</p>

          <Register />
        </div>
      </div>

      <div className="hidden md:block col-span-5 col-end-13">
        <img src={heroImg} width="100%" />
      </div>
    </div>
  )
}

export default Hero
