import * as React from "react"

import configGraphic from "../../images/config-graphic.svg"

const Config = () => {
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 md:col-span-4 flex flex-row items-center">
        <div>
          <h2 className="mb-6">Boundary config lives in your source code</h2>

          <p className="mb-10">No more disjointed CMS and source code. Boundary configuration lives in your code, so whenever you make a change to your project, the CMS configuration changes with it.</p>
        </div>
      </div>

      <div className="col-span-12 md:col-span-8">
        <img src={configGraphic} width="100%" />
      </div>
    </div>
  )
}

export default Config
