import React from "react"

import documentScreenshot from "../../images/screenshots/document-screenshot.png"
import documentsScreenshot from "../../images/screenshots/documents-screenshot.png"
import documentsSearchScreenshot from "../../images/screenshots/documents-search-screenshot.png"
import documentSettingsScreenshot from "../../images/screenshots/document-settings-screenshot.png"
import templateScreenshot from "../../images/screenshots/template-screenshot.png"

const images = [
  documentScreenshot,
  documentsScreenshot,
  documentsSearchScreenshot,
  documentSettingsScreenshot,
  templateScreenshot,
]

const Interface = () => {
  return (
    <div className="bg-primary-800">
      <div className="container mx-auto px-2">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-7">
            <div className="overflow-hidden relative" style={{ height: 660 }}>
              <div className="absolute">
                <img src={documentScreenshot} className="first block mb-3 rounded" alt="" />
                <img src={documentsScreenshot} className="block mb-3 rounded" alt="" />
                <img src={documentsSearchScreenshot} className="block mb-3 rounded" alt="" />
                <img src={documentSettingsScreenshot} className="block mb-3 rounded" alt="" />
                <img src={templateScreenshot} className="block mb-3 rounded" alt="" />
                <img src={documentScreenshot} className="block mb-3 rounded" alt="" />
                <img src={documentsScreenshot} className="block mb-3 rounded" alt="" />
              </div>
            </div>
          </div>

          <div className="col-span-12 md:col-span-4 md:col-end-13 flex flex-row items-center">
            <div>
              <h2 className="mb-6">An elegant interface for content managers</h2>

              <p className="">Boundary offers a simple and easy-to-use interface that is designed to reduce human error and make users feel happy, not pain.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Interface
