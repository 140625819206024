import * as React from "react"

import check from "../../images/check-white.svg"
import formValidationIcon from "../../images/form-validation-icon.svg"
import fieldTypesIcon from "../../images/field-types-icon.svg"
import customHooksIcon from "../../images/custom-hooks-icon.svg"
import globalConfigIcon from "../../images/global-config-icon.svg"

const Features = () => {
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 lg:col-span-7 order-last lg:order-first">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:pr-12">
          <div className="bg-primary-700 rounded-lg pl-6 pr-10 py-8">
            <div className="flex items-end mb-4" style={{height: 60}}><img src={formValidationIcon} /></div>

            <h4 className="mb-2 text-2xl">Form validation</h4>

            <p>Make use of form validation such as required fields, data types, email &amp; more.</p>
          </div>

          <div className="bg-primary-500 rounded-lg pl-6 pr-10 py-8 md:-mr-3 md:-mt-3">
            <div className="pt-3 pr-3">
              <div className="flex items-end mb-4" style={{height: 60}}><img src={fieldTypesIcon} /></div>

              <h4 className="mb-2 text-2xl">14 field types</h4>

              <p>Configure forms using standard fields for any type of data input including plain text, rich text, markdown, document select, code, dropdown, date and more.</p>
            </div>
          </div>

          <div className="bg-primary-700 rounded-lg pl-6 pr-10 py-8">
            <div className="flex items-end mb-4" style={{height: 60}}><img src={customHooksIcon} /></div>

            <h4 className="mb-2 text-2xl">Custom hooks</h4>

            <p>Build in hooks to run code on certain events such as publish, edit, &amp; delete.</p>
          </div>

          <div className="bg-primary-700 rounded-lg pl-6 pr-10 py-8">
            <div className="flex items-end mb-4" style={{height: 60}}><img src={globalConfigIcon} /></div>

            <h4 className="mb-2 text-2xl">Global config</h4>

            <p>Seamlessly globally configure anything with all the same validation and hook functionality as document editing.</p>
            <p>&nbsp;</p>
          </div>
        </div>
      </div>

      <div className="col-span-12 lg:col-span-5 flex flex-row items-center">
        <div>
          <h2 className="mb-6">Warp Boundary to fit any project</h2>

          <p className="mb-10">Boundary provides the best experience for creating editable websites or apps for developers.</p>

          <div>
            <div className="flex items-center mb-5">
              <div className="mr-3"><img src={check} /></div>
              
              <div>The advantages of self managed, without the hassle</div>
            </div>

            <div className="flex items-center mb-5">
              <div className="mr-3"><img src={check} /></div>
              
              <div>Own your own data with direct database access</div>
            </div>

            <div className="flex items-center mb-5">
              <div className="mr-3"><img src={check} /></div>
              
              <div>Highly flexible configuration options</div>
            </div>

            <div className="flex items-center mb-5">
              <div className="mr-3"><img src={check} /></div>
              
              <div>A super low latency GraphQL backend</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Features
