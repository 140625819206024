import React, { useState } from "react"

import check from "../../images/check-white.svg"
import caretRight from "../../images/caret-right.svg"

const prices = {
  monthly: {
    individual: 0,
    startup: 40,
    business: 300,
    scale: 750,
  },
  yearly: {
    individual: 0,
    startup: 34,
    business: 250,
    scale: 625,
  }
}

const activeButton = "relative bg-white rounded-md shadow-sm py-2 w-1/2 text-primary-800 text-sm font-semibold whitespace-nowrap focus:outline-none sm:w-auto sm:px-8"
const emptyButton = "relative rounded-md py-2 w-1/2 text-sm font-semibold text-primary-800 whitespace-nowrap focus:outline-none sm:w-auto sm:px-8"

const Pricing = () => {
  const [monthlyYearly, setMonthlyYearly] = useState('yearly')

  return (
    <>
      <div className="flex flex-col md:flex-row mb-8">
        <div>
          <p className="mb-2">All plans include</p>

          <div className="flex flex-wrap flex-col md:flex-row md:w-4/5">
            <div className="flex items-center mb-2 mr-6">
              <div className="mr-2"><img src={check} width="16" /></div>
              
              <div>Unlimited documents</div>
            </div>

            <div className="flex items-center mb-2 mr-6">
              <div className="mr-2"><img src={check} width="16" /></div>
              
              <div>Unlimited document types</div>
            </div>

            <div className="flex items-center mb-2 mr-6">
              <div className="mr-2"><img src={check} width="16" /></div>
              
              <div>Unlimited environments</div>
            </div>

            <div className="flex items-center mb-2 mr-6">
              <div className="mr-2"><img src={check} width="16" /></div>
              
              <div>No limits on features</div>
            </div>

            <div className="flex items-center mb-2 mr-6">
              <div className="mr-2"><img src={check} width="16" /></div>
              
              <div>User role options</div>
            </div>
          </div>
        </div>

        <div className="ml-auto">
          <div className="flex flex-row">
            <div className="relative mt-6 bg-primary-200 rounded-lg p-0.5 flex self-center sm:mt-8">
              <button
                type="button"
                className={`${monthlyYearly === 'monthly' ? activeButton : emptyButton}`}
                onClick={() => {
                  setMonthlyYearly('monthly')
                }}
              >
                Monthly billing
              </button>

              <button
                type="button"
                className={`ml-0.5 ${monthlyYearly === 'yearly' ? activeButton : emptyButton}`}
                onClick={() => {
                  setMonthlyYearly('yearly')
                }}
              >
                Yearly billing
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-6 lg:col-span-3">
          <div className="bg-primary-700 rounded-lg px-6 py-8 h-full flex flex-col">
            <h5 className="text-2xl mb-2">Individual</h5>

            <div className="text-4xl font-semibold">${monthlyYearly === 'monthly' ? prices.monthly.individual : prices.yearly.individual}</div>

            <p className="mb-8 text-sm">per month billed {monthlyYearly}</p>

            <p className="font-semibold">This plan includes</p>

            <div className="grid grid-cols-1 gap-1 mb-8">
              <div className="flex">
                <img src={caretRight} className="mr-2" />

                <div>25GB asset storage</div>
              </div>

              <div className="flex">
                <img src={caretRight} className="mr-2" />

                <div>100GB asset bandwidth</div>
              </div>

              <div className="flex">
                <img src={caretRight} className="mr-2" />

                <div>Basic support</div>
              </div>

              <div className="flex">
                <img src={caretRight} className="mr-2" />

                <div>1 user</div>
              </div>

              <div className="flex">
                <img src={caretRight} className="mr-2" />

                <div>500k API requests</div>
              </div>
            </div>

            <div className="border-4 border-primary-100 text-center rounded px-2 py-2 font-semibold mt-auto">Launching soon</div>
          </div>
        </div>

        <div className="col-span-12 md:col-span-6 lg:col-span-3">
          <div className="bg-primary-700 rounded-lg px-6 py-8 h-full flex flex-col">
            <h5 className="text-2xl mb-2">Startup</h5>

            <div className="text-4xl font-semibold">${monthlyYearly === 'monthly' ? prices.monthly.startup : prices.yearly.startup}</div>

            <p className="mb-8 text-sm">per month billed {monthlyYearly}</p>

            <p className="font-semibold">This plan includes</p>

            <div className="grid grid-cols-1 gap-1 mb-8">
              <div className="flex">
                <img src={caretRight} className="mr-2" />

                <div>150GB asset storage</div>
              </div>

              <div className="flex">
                <img src={caretRight} className="mr-2" />

                <div>500GB asset bandwidth</div>
              </div>

              <div className="flex">
                <img src={caretRight} className="mr-2" />

                <div>Basic support</div>
              </div>

              <div className="flex">
                <img src={caretRight} className="mr-2" />

                <div>25 users</div>
              </div>

              <div className="flex">
                <img src={caretRight} className="mr-2" />

                <div>Unlimited API requests</div>
              </div>
            </div>

            <div className="border-4 border-primary-100 text-center rounded px-2 py-2 font-semibold mt-auto">Launching soon</div>
          </div>
        </div>

        <div className="col-span-12 md:col-span-6 lg:col-span-3">
          <div className="bg-primary-700 rounded-lg px-6 py-8 h-full flex flex-col">
            <h5 className="text-2xl mb-2">Business</h5>

            <div className="text-4xl font-semibold">${monthlyYearly === 'monthly' ? prices.monthly.business : prices.yearly.business}</div>

            <p className="mb-8 text-sm">per month billed {monthlyYearly}</p>

            <p className="font-semibold">This plan includes</p>

            <div className="grid grid-cols-1 gap-1 mb-8">
              <div className="flex">
                <img src={caretRight} className="mr-2" />

                <div>1TB asset storage</div>
              </div>

              <div className="flex">
                <img src={caretRight} className="mr-2" />

                <div>Unlimited asset bandwidth</div>
              </div>

              <div className="flex">
                <img src={caretRight} className="mr-2" />

                <div>Priority support</div>
              </div>

              <div className="flex">
                <img src={caretRight} className="mr-2" />

                <div>100 users</div>
              </div>

              <div className="flex">
                <img src={caretRight} className="mr-2" />

                <div>Unlimited API requests</div>
              </div>
            </div>

            <div className="border-4 border-primary-100 text-center rounded px-2 py-2 font-semibold mt-auto">Launching soon</div>
          </div>
        </div>

        <div className="col-span-12 md:col-span-6 lg:col-span-3">
          <div className="bg-primary-700 rounded-lg px-6 py-8 h-full flex flex-col">
            <h5 className="text-2xl mb-2">Scale</h5>

            <div className="text-4xl font-semibold">${monthlyYearly === 'monthly' ? prices.monthly.scale : prices.yearly.scale}</div>

            <p className="mb-8 text-sm">per month billed {monthlyYearly}</p>

            <p className="font-semibold">This plan includes</p>

            <div className="grid grid-cols-1 gap-1 mb-8">
              <div className="flex">
                <img src={caretRight} className="mr-2" />

                <div>Unlimited asset storage</div>
              </div>

              <div className="flex">
                <img src={caretRight} className="mr-2" />

                <div>Unlimited asset bandwidth</div>
              </div>

              <div className="flex">
                <img src={caretRight} className="mr-2" />

                <div>Priority support</div>
              </div>

              <div className="flex">
                <img src={caretRight} className="mr-2" />

                <div>Unlimited users</div>
              </div>

              <div className="flex">
                <img src={caretRight} className="mr-2" />

                <div>Unlimited API requests</div>
              </div>
            </div>

            <div className="border-4 border-primary-100 text-center rounded px-2 py-2 font-semibold mt-auto">Launching soon</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Pricing
