import * as React from "react"

import Header from "../components/layout/header"
import Footer from "../components/layout/footer"
import Hero from "../sections/index/hero"
import Features from "../sections/index/features"
import Config from "../sections/index/config"
import Interface from "../sections/index/interface"
import Pricing from "../sections/index/pricing"
import CTA from "../sections/index/cta"
import Spacer from "../components/ui/spacer"

const IndexPage = () => {
  return (
    <div className="min-h-screen bg-primary-900 text-white">
      <div className="container mx-auto px-2">
        <Header />

        <section>
          <Hero />
        </section>

        <Spacer size="lg" />

        <section>
          <Features />
        </section>

        <Spacer size="lg" />

        <section>
          <Config />
        </section>

        <Spacer size="lg" />
      </div>

      <section>
        <Interface />
      </section>

      <Spacer size="lg" />

      <div className="container mx-auto px-2">
        <section>
          <Pricing />
        </section>

        <Spacer size="lg" />

        <section>
          <CTA />
        </section>

        <Spacer size="sm" />

        <Footer />
      </div>
    </div>
  )
}

export const Head = () => <>
  <title>Boundary CMS - The most developer friendly CMS</title>

  <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
  <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
  <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
  <link rel="manifest" href="/site.webmanifest" />
  <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#515BE7" />
  <meta name="msapplication-TileColor" content="#515BE7" />
  <meta name="theme-color" content="#ffffff" />
</>

export default IndexPage
