import * as React from "react"

import Register from "../../components/ui/register"

import bgImage from "../../images/cta-bg.svg"

const CTA = () => {
  return (
    <div className="flex flex-col items-center text-center rounded-lg bg-cover py-32 sm:py-48 px-3 md:px-10" style={{ backgroundImage: `url(${bgImage})` }}>
      <h2 className="mb-2">The CMS future starts now</h2>

      <p className="mb-12">Register for early access and be the first to know when Boundary goes live!</p>

      <div className="w-full md:w-4/5 lg:w-3/5 xl:w-2/4">
        <Register />
      </div>
    </div>
  )
}

export default CTA
