import * as React from "react"

import Button from "../ui/button"

const Register = () => {
  return (
    <>
      <form action="https://live.us8.list-manage.com/subscribe/post" method="POST">
        <input type="hidden" name="u" value="fe4d792251035f93b8aca00be" />
        <input type="hidden" name="id" value="c83bd6fe42" />

        <div className="flex justify-between flex-col sm:flex-row bg-primary-100 rounded p-1">
          <input type="email" autoCapitalize="off" autoCorrect="off" name="MERGE0" id="MERGE0" className="bg-primary-100 text-black flex-1 outline-0 pl-3 py-3" placeholder="Enter your email address" />

          <Button variant="primary" size="large">Register for early access</Button>
        </div>
      </form>

      <p className="text-sm mt-2">Register early for $100 credit! We'll only email you once we're live.</p>
    </>
  )
}

export default Register
