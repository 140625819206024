import React, { forwardRef } from "react"
import PropTypes from "prop-types"

import { cls } from "../../utils/helpers"

const classes = {
  base: 'rounded font-semibold inline-block',
  size: {
    normal: 'px-1 py-1 text-xs',
    normal: 'px-3 py-1 text-sm',
    large: 'px-4 py-2',
  },
  variant: {
    'primary-light': 'bg-primary-100 text-primary-500',
    'primary': 'bg-primary-500 text-white',
    'primary-dark': 'bg-primary-700 text-white',
    'danger': 'bg-red-500 text-white'
  }
}

const Badge = forwardRef(
  (
    {
      children,
      className,
      variant = 'primary',
      size = 'normal',
      ...props
    }, ref
  ) => (
    <div
      ref={ref}
      className={cls(`
        ${classes.base}
        ${classes.size[size]}
        ${classes.variant[variant]}
        ${className}
      `)}
      {...props}
    >
      {children}
    </div>
  ));

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    'primary-light',
    'primary',
    'primary-dark',
    'secondary',
    'danger',
  ]),
  size: PropTypes.oneOf([
    'small',
    'normal',
    'large',
  ])
}

export default Badge
